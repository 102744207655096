import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    footer: {
        border: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
}));

const Footer = () => {

    const classes = useStyles();

    return (
        <Fragment>
            <footer className={classes.footer}>
                <Container maxWidth="md">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                marginTop={2}>
                                <Typography variant="subtitle1" style={{textTransform: "none", opacity: "0.75"}} color="primary">
                                    CovQ - The Compound Value Creator Quotient. Deep Impact Creates Value Building.
                                </Typography>
                                <Typography variant="subtitle2" style={{textTransform: "none", opacity: "0.75"}} color="primary">
                                    Copyright © {new Date().getFullYear()} 8W8 Advisors Inc. All rights reserved.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </footer>
        </Fragment>
    )
}

export default Footer;