import React, {Fragment, useState} from "react";
import {useRouter} from "next/router";
import MuiMenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import {connect} from "react-redux";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Avatar} from "@material-ui/core";
import {colors} from "../../../constants";

const MenuItem = withStyles({
    root: {
        color: colors.blue,
        backgroundColor: "white",
        fontSize: "0.9rem",
        "&:hover": {
            color: "white",
            backgroundColor: colors.blue,
        }
    },
})(MuiMenuItem);

const useStyles = makeStyles((theme) => ({
    0: {
        color: theme.palette.getContrastText(colors.orange),
        backgroundColor: colors.orange,
    },
    1: {
        color: theme.palette.getContrastText(colors.black),
        backgroundColor: colors.black,
    },
    2: {
        color: theme.palette.getContrastText(colors.green),
        backgroundColor: colors.green,
    },
    3: {
        color: theme.palette.getContrastText(colors.blue),
        backgroundColor: colors.blue,
    },
    4: {
        color: theme.palette.getContrastText(colors.red),
        backgroundColor: colors.red,
    },
}));

const DesktopMenuBar = ({member}) => {

    const classes = useStyles();

    const router = useRouter();

    const [anchorElOptions, setAnchorElOptions] = useState(null);
    const [anchorElAccount, setAnchorElAccount] = useState(null);

    const onOptionsMenuOpen = (event) => {
        setAnchorElOptions(event.currentTarget);
    };

    const onOptionsMenuClose = () => {
        setAnchorElOptions(null);
    };

    const onAccountMenuOpen = (event) => {
        setAnchorElAccount(event.currentTarget);
    };

    const onAccountMenuClose = () => {
        setAnchorElAccount(null);
    };

    const onNavigate = async (url) => {
        await router.push(url);
    }

    const onLogout = async () => {

        await router.replace("/login");
    }

    return (
        <Fragment>
            {member && member.isSuper && (
                <Fragment>
                    <MenuItem aria-controls="Companies" aria-haspopup="true" onClick={async () => onNavigate("/companies")}>
                        Companies
                    </MenuItem>
                    <MenuItem aria-controls="Drivers" aria-haspopup="true" onClick={async () => onNavigate("/value-drivers")}>
                        Drivers
                    </MenuItem>
                    <MenuItem aria-controls="Users" aria-haspopup="true" onClick={async () => onNavigate("/users")}>
                        Users
                    </MenuItem>
                    <MenuItem aria-controls="Roles" aria-haspopup="true" onClick={async () => onNavigate("/roles")}>
                        Roles
                    </MenuItem>
                    <MenuItem aria-controls="Permissions" aria-haspopup="true" onClick={async () => onNavigate("/permissions")}>
                        Permissions
                    </MenuItem>
                    <MenuItem aria-controls="Options" aria-haspopup="true" onClick={onOptionsMenuOpen}>
                        Options<IconExpandMore/>
                    </MenuItem>
                    <Menu
                        id="options"
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        anchorEl={anchorElOptions}
                        keepMounted
                        open={Boolean(anchorElOptions)}
                        onClose={onOptionsMenuClose}>
                        <MenuItem aria-controls="Industries" aria-haspopup="true" onClick={async () => onNavigate("/options/industries")}>
                            Industries
                        </MenuItem>
                        <MenuItem aria-controls="Scales" aria-haspopup="true" onClick={async () => onNavigate("/options/scales")}>
                            Scales
                        </MenuItem>
                        <MenuItem aria-controls="Stages" aria-haspopup="true" onClick={async () => onNavigate("/options/stages")}>
                            Stages
                        </MenuItem>
                        <MenuItem aria-controls="Countries" aria-haspopup="true" onClick={async () => onNavigate("/options/countries")}>
                            Countries
                        </MenuItem>
                    </Menu>
                    <MenuItem aria-controls="VD Engine" aria-haspopup="true" onClick={async () => onNavigate("/vd-engine")}>
                        VD Engine
                    </MenuItem>
                </Fragment>
            )}
            <IconButton
                edge="end"
                aria-label="account"
                aria-haspopup="true"
                onClick={onAccountMenuOpen}
                color="inherit">
                <Avatar className={classes[member && member.color]}>
                    {member && (member.firstName.charAt(0) + member.lastName.charAt(0))}
                </Avatar>
            </IconButton>
            <Menu
                id="account-menu"
                anchorEl={anchorElAccount}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElAccount)}
                onClose={onAccountMenuClose}>
                <MenuItem onClick={async () => onNavigate("/account/profile")}>Profile</MenuItem>
                <MenuItem onClick={async () => onLogout()}>Sign out</MenuItem>
            </Menu>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return ({
        member: state.member.member
    });
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DesktopMenuBar);
