import React, {createRef, Fragment, RefObject, useEffect, useRef, useState} from "react";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import {makeStyles, createStyles, withStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import SportsMotorsportsRoundedIcon from '@material-ui/icons/SportsMotorsportsRounded';
import SpeedRoundedIcon from '@material-ui/icons/SpeedRounded';
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import BusinessTwoToneIcon from "@material-ui/icons/BusinessTwoTone";
import AssessmentTwoToneIcon from "@material-ui/icons/AssessmentTwoTone";
import GroupTwoToneIcon from "@material-ui/icons/GroupTwoTone";
import AccountTreeTwoToneIcon from "@material-ui/icons/AccountTreeTwoTone";
import CallToActionTwoToneIcon from "@material-ui/icons/CallToActionTwoTone";
import CenterFocusStrongTwoToneIcon from "@material-ui/icons/CenterFocusStrongTwoTone";
import DescriptionTwoToneIcon from "@material-ui/icons/DescriptionTwoTone";
import TrackChangesTwoToneIcon from "@material-ui/icons/TrackChangesTwoTone";
import AssignmentTwoToneIcon from "@material-ui/icons/AssignmentTwoTone";
import TimelineTwoToneIcon from "@material-ui/icons/TimelineTwoTone";
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import WidgetsTwoToneIcon from '@material-ui/icons/WidgetsTwoTone';
import ViewHeadlineTwoToneIcon from '@material-ui/icons/ViewHeadlineTwoTone';
import FlightTakeoffTwoToneIcon from '@material-ui/icons/FlightTakeoffTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import {useRouter} from "next/router";
import {colors, permissionsActions, permissionsPages} from "@constants";
import {hasAnyPermission, hasCompanyLevelPermission} from "@helpers";

const drawerWidth = 240

const ListItem = withStyles({
    root: {
        "&": {
            backgroundColor: "white",
            color: colors.blue,
            margin: "1px 0",
            "& .MuiListItemIcon-root": {
                color: colors.blue
            },
        },
        "&$selected": {
            backgroundColor: colors.blue,
            color: "white",
            margin: "1px 0",
            "& .MuiListItemIcon-root": {
                color: "white"
            }
        },
        "&$selected:hover": {
            backgroundColor: colors.blue,
            color: "white",
            margin: "1px 0",
            "& .MuiListItemIcon-root": {
                color: "white"
            }
        },
        "&:hover": {
            backgroundColor: colors.blue,
            color: "white",
            margin: "1px 0",
            "& .MuiListItemIcon-root": {
                color: "white"
            }
        }
    },
    selected: {}
})(MuiListItem);

const useStyles = makeStyles(() =>
    createStyles({
        appMenu: {
            width: "100%",
            overflowX: "hidden"
        },
        navList: {
            width: drawerWidth,
            overflowX: "hidden"
        },
    }),
)

const max = 18;
const suffix = "...";

const truncate = (str) => (str.length) < max ? str : `${str.substring(0, max - suffix.length)}${suffix}`;

const SideBar = ({
                     member,
                     sidebarMenus,
                     selectSideBarMenu,
                     selectedSideBarMenu,
                     selectSideBarSubMenu,
                     selectedSideBarSubMenu,
                     searchSidebarMenus,
                     searchSidebarMenusFilter,
                 }) => {

    const [state, setState] = useState({
        scrollIntoView: true,
    });

    const sidebarMenusRef = useRef(sidebarMenus.map(() => createRef()));

    const [sideBarMenusFilter, setSideBarMenusFilter] = useState([]);

    const classes = useStyles();

    const router = useRouter();

    const onExpand = (sideBarMenu) => {

        setState(prevState => ({
            ...prevState,
            scrollIntoView: false,
        }));

        if (selectedSideBarMenu && selectedSideBarMenu.id === sideBarMenu.id) {

            selectSideBarMenu(null);
        } else {

            selectSideBarMenu(sideBarMenu);
        }
    }

    const onNavigate = async (sideBarSubMenu) => {

        selectSideBarSubMenu(sideBarSubMenu);

        await router.push(sideBarSubMenu)
    }

    const onSearch = (event) => {

        event.preventDefault();

        const value = event.target.value;
        console.log(value)
        searchSidebarMenus(value)
    }

    useEffect(() => {

        const sideBarMenusFilter = searchSidebarMenusFilter ? [...sidebarMenus]
            .filter((sidebarUrl) => (sidebarUrl.name.toLowerCase().includes(searchSidebarMenusFilter.toLowerCase()))) : [...sidebarMenus]

        setSideBarMenusFilter(sideBarMenusFilter);

    }, [sidebarMenus, searchSidebarMenusFilter])

    useEffect(() => {

        if (state.scrollIntoView && selectedSideBarMenu) {

            const idx = sideBarMenusFilter.map((sideBarMenu) => sideBarMenu.id).indexOf(selectedSideBarMenu.id);

            if (idx === -1)
                return;

            if (sidebarMenusRef.current && sidebarMenusRef.current[idx] && sidebarMenusRef.current[idx].current) {

                sidebarMenusRef.current[idx].current.scrollIntoView();
            }
        }
    }, [sideBarMenusFilter, selectedSideBarMenu])

    return (
        <List component="nav" className={classes.appMenu} disablePadding>
            <MuiListItem button>
                <Tooltip title="Search" placement="top">
                    <TextField id="search" autoComplete="chrome-off" type="search"
                               fullWidth
                               onChange={onSearch}
                               value={searchSidebarMenusFilter} InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchRoundedIcon/>
                            </InputAdornment>
                        ),
                        autoComplete: 'chrome-off'
                    }}/>
                </Tooltip>
            </MuiListItem>
            <ListItem button
                      onClick={async () => {

                          selectSideBarMenu(null);
                          await onNavigate(`/account/cockpit`);
                      }}>
                <Tooltip title="Cockpit" placement="top">
                    <ListItemIcon>
                        <SportsMotorsportsRoundedIcon/>
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Cockpit"/>
            </ListItem>
            <Fragment>
                {member && (hasAnyPermission(member.permissions, member.usersCompaniesRoles, permissionsPages.academy, permissionsActions.read) && (
                    <ListItem button
                              onClick={async () => {

                                  selectSideBarMenu(null);
                                  await onNavigate(`/account/vd-engine`);
                              }}>
                        <Tooltip title="VD Engine" placement="top">
                            <ListItemIcon>
                                <SpeedRoundedIcon/>
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="VD Engine"/>
                    </ListItem>
                ))}
            </Fragment>
            {sideBarMenusFilter.map((sideBarMenu, idx) => (
                <Fragment key={`sideBarMenu-${sideBarMenu.id}`}>
                    {member && (hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.users, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.companyValueDrivers, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.assessments, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.scores, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.answers, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.prioItems, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.prioActions, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.strategicInitiatives, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.actionItems, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.cycles, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.reports, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.execHub, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.execHubSections, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.execHubWidgets, permissionsActions.read)
                            || hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.settings, permissionsActions.read))
                        && (
                            <Fragment>
                                <div ref={sidebarMenusRef.current[idx] as RefObject<HTMLDivElement>}>
                                    <ListItem button onClick={async () => onExpand(sideBarMenu)}>
                                        <Tooltip title={sideBarMenu.name} placement="top">
                                            <ListItemIcon>
                                                <BusinessTwoToneIcon/>
                                            </ListItemIcon>
                                        </Tooltip>
                                        <Tooltip title={sideBarMenu.name} placement="top">
                                            <ListItemText primary={truncate(sideBarMenu.name)}/>
                                        </Tooltip>
                                        {(selectedSideBarMenu && selectedSideBarMenu.id === sideBarMenu.id) ?
                                            <IconExpandLess/> :
                                            <IconExpandMore/>}
                                    </ListItem>
                                    <Collapse in={selectedSideBarMenu && selectedSideBarMenu.id === sideBarMenu.id}
                                              timeout="auto"
                                              unmountOnExit>
                                        <Divider/>
                                        <List component="div" disablePadding>
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.users, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.users}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.users}`)}>
                                                    <Tooltip title="Users" placement="top">
                                                        <ListItemIcon>
                                                            <GroupTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Users"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.companyValueDrivers, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.companyValueDrivers}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.companyValueDrivers}`)}>
                                                    <Tooltip title="Value Drivers" placement="top">
                                                        <ListItemIcon>
                                                            <FlightTakeoffTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Value Drivers"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.assessments, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.assessments}`}
                                                          onClick={async () => await onNavigate(`/account/companies/${sideBarMenu.assessments}`)}>
                                                    <Tooltip title="Assessments" placement="top">
                                                        <ListItemIcon>
                                                            <AssessmentTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Assessments"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.prioItems, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.prios}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.prios}`)}>
                                                    <Tooltip title="PRIOs" placement="top">
                                                        <ListItemIcon>
                                                            <AccountTreeTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="PRIOs"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.prioActions, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.prioactions}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.prioactions}`)}>
                                                    <Tooltip title="PRIO Actions" placement="top">
                                                        <ListItemIcon>
                                                            <CallToActionTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="PRIO Actions"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.strategicInitiatives, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.strategicinitiatives}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.strategicinitiatives}`)}>
                                                    <Tooltip title="Strategic Initiatives" placement="top">
                                                        <ListItemIcon>
                                                            <CenterFocusStrongTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Strategic Initiatives"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.actionItems, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.actionitems}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.actionitems}`)}>
                                                    <Tooltip title="Action Items" placement="top">
                                                        <ListItemIcon>
                                                            <DescriptionTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Action Items"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.cycles, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.cycles}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.cycles}`)}>
                                                    <Tooltip title="Cycles" placement="top">
                                                        <ListItemIcon>
                                                            <TrackChangesTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Cycles"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.cycles, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.board}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.board}`)}>
                                                    <Tooltip title="Board" placement="top">
                                                        <ListItemIcon>
                                                            <AssignmentTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Board"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.reports, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.reports}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.reports}`)}>
                                                    <Tooltip title="Reports" placement="top">
                                                        <ListItemIcon>
                                                            <TimelineTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Reports"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.execHub, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.exechub}`}
                                                          onClick={async () => {

                                                              await onNavigate(`/account/companies/${sideBarMenu.exechub}`)
                                                          }}>
                                                    <Tooltip title="Exec Hub" placement="top">
                                                        <ListItemIcon>
                                                            <DashboardTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Exec Hub"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.execHubSections, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.exechubsections}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.exechubsections}`)}>
                                                    <Tooltip title="Exec Hub Sections" placement="top">
                                                        <ListItemIcon>
                                                            <ViewHeadlineTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Exec Hub Sections"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.execHubWidgets, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.exechubwidgets}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.exechubwidgets}`)}>
                                                    <Tooltip title="Exec Hub Widgets" placement="top">
                                                        <ListItemIcon>
                                                            <WidgetsTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Exec Hub Widgets"/>
                                                </ListItem>
                                            )}
                                            {member && hasCompanyLevelPermission(member.permissions, member.usersCompaniesRoles, sideBarMenu.id, permissionsPages.settings, permissionsActions.read) && (
                                                <ListItem button
                                                          selected={selectedSideBarSubMenu && selectedSideBarSubMenu === `/account/companies/${sideBarMenu.settings}`}
                                                          onClick={async () => onNavigate(`/account/companies/${sideBarMenu.settings}`)}>
                                                    <Tooltip title="Settings" placement="top">
                                                        <ListItemIcon>
                                                            <SettingsTwoToneIcon/>
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText primary="Settings"/>
                                                </ListItem>
                                            )}
                                        </List>
                                        <Divider/>
                                    </Collapse>
                                </div>
                            </Fragment>
                        )}
                </Fragment>
            ))}
        </List>
    )
}

export default SideBar