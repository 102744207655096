import {SIDE_MENUBAR_ACTION_TYPES, SESSION_ACTION_TYPES} from "../constants/actionTypes";

export const selectSideBarMenu = (id) => {
    if (id != null) {
        return dispatch => {
            dispatch({
                type: SIDE_MENUBAR_ACTION_TYPES.SIDE_BAR_SELECT_MENU,
                payload: id,
            })
        }
    } else {
        return dispatch => {
            dispatch({
                type: SIDE_MENUBAR_ACTION_TYPES.SIDE_BAR_SELECT_MENU,
                payload: null,
            })
        }
    }
}

export const selectSideBarSubMenu = (id) => {

    if (id != null) {
        return dispatch => {
            dispatch({
                type: SIDE_MENUBAR_ACTION_TYPES.SIDE_BAR_SELECT_SUBMENU,
                payload: id,
            })
        }
    } else {
        return dispatch => {
            dispatch({
                type: SIDE_MENUBAR_ACTION_TYPES.SIDE_BAR_SELECT_SUBMENU,
                payload: null,
            })
        }
    }
}

export const searchSidebarMenus = (searchFilter) => {

    if (searchFilter != null) {
        return dispatch => {
            dispatch({
                type: SESSION_ACTION_TYPES.SESSION_SIDE_BAR_SEARCH_MENUS_FILTER,
                payload: searchFilter,
            })
        }
    } else {
        return dispatch => {
            dispatch({
                type: SESSION_ACTION_TYPES.SESSION_SIDE_BAR_SEARCH_MENUS_FILTER,
                payload: "",
            })
        }
    }
}