const hasAnyPermission = (permissions, usersCompaniesRoles, page, action) => {

    const roleIds = Array.from(new Set(usersCompaniesRoles.map((userCompanyRole) => userCompanyRole.roleId)));

    return permissions
        .filter((permission) => roleIds.includes(permission.roleId))
        .some((permission) => {
            return permission.page === page && permission.action === action;
        });
}

const hasCompanyLevelPermission = (permissions, usersCompaniesRoles, companyId, page, action) => {

    const roleIds = usersCompaniesRoles
        .filter((userCompanyRole) => userCompanyRole.companyId === companyId)
        .map((userCompanyRole) => userCompanyRole.roleId);

    return permissions
        .filter((permission) => roleIds.includes(permission.roleId))
        .some((permission) => {
            return permission.page === page && permission.action === action;
        });
}

const getAssignee = (assignee) => {

    return assignee ? assignee.firstName + " " + assignee.lastName : "";
}

const randomString = () => {

    let d = new Date().getTime();
    return 'xxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 32) % 32 | 0;
        d = Math.floor(d / 32);
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(32);
    });
}

export {getAssignee, hasCompanyLevelPermission, hasAnyPermission, randomString}