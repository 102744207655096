import React, {Fragment} from "react";
import {connect} from "react-redux";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Head from "next/head";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import withAuth from "./withAuth";
import {DesktopMenuBar, MobileMenuBar} from "./MenuBar";
import SideBar from "./SideBar";
import Footer from "./Footer";
import {searchSidebarMenus, selectSideBarMenu, selectSideBarSubMenu} from "./effects";

const drawerWidth = 240;
const boxHeight = 230;

// import {useTranslation} from "react-i18next";
const favicon = require("../assets/favicon.png");
const logo = require("../assets/logo-no-tagline.svg");
const background = require("../assets/images/background.png");

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: "rgba(255,255,255,1)",
        color: theme.palette.common.black,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 24,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overflow: "hidden"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        width: '100%',
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: "white",
        backgroundAttachment: "fixed",
        backgroundSize: 'auto',
        backgroundPosition: "top right",
    },
    boxContent: {
        minHeight: `calc(100vh - ${boxHeight}px)`,
        backgroundColor: "rgba(255,255,255,1)",
        overflowY: 'auto',
        opacity: "0.925",
    },
    width100: {
        width: '100%',
    },
    menu: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black
    },
    grow: {
        flexGrow: 1,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    }
}));

const Layout = ({
                    children,
                    member,
                    selectSideBarMenu,
                    selectedSideBarMenu,
                    selectSideBarSubMenu,
                    selectedSideBarSubMenu,
                    searchSidebarMenus,
                    searchSidebarMenusFilter,
                    sidebarMenus,
                }) => {

    // const {t} = useTranslation();

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Head>
                <title>CovQ</title>
                <link rel="icon" href={favicon}/>
            </Head>
            <div className={classes.root}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}>
                            <MenuIcon/>
                        </IconButton>
                        <Box display="flex"
                             flexDirection="row"
                             justifyContent="center"
                             alignItems="center"
                             alignContent="center">
                            <img alt="CovQ" src={logo} width="140px" height="50px"/>
                        </Box>
                        <div className={classes.grow}/>
                        <div className={classes.sectionDesktop}>
                            <DesktopMenuBar/>
                        </div>
                        <div className={classes.sectionMobile}>
                            <MobileMenuBar/>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}>
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                        </IconButton>
                    </div>
                    <Divider/>
                    <SideBar
                        member={member}
                        sidebarMenus={sidebarMenus}
                        selectSideBarMenu={selectSideBarMenu}
                        selectedSideBarMenu={selectedSideBarMenu}
                        selectSideBarSubMenu={selectSideBarSubMenu}
                        selectedSideBarSubMenu={selectedSideBarSubMenu}
                        searchSidebarMenus={searchSidebarMenus}
                        searchSidebarMenusFilter={searchSidebarMenusFilter}/>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.toolbar}/>
                    <Box className={classes.boxContent}
                         borderRadius={9}
                         margin={2}
                         boxShadow={1}>
                        <Box marginLeft={2} marginRight={2} padding={2}>
                            {children}
                        </Box>
                    </Box>
                    <Divider/>
                    <Footer/>
                </main>
            </div>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return ({
        member: state.member.member,
        sidebarMenus: state.session.sidebarMenus,
        selectedSideBarMenu: state.sidebar.selectedSideBarMenu,
        selectedSideBarSubMenu: state.sidebar.selectedSideBarSubMenu,
        searchSidebarMenusFilter: state.session.searchSidebarMenusFilter,
        userAssessments: state.session.userAssessments,
    });
};

const mapDispatchToProps = {
    selectSideBarMenu,
    selectSideBarSubMenu,
    searchSidebarMenus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(Layout));


