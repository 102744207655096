import {useEffect} from "react";
import {useRouter} from "next/router";
import {verifyToken} from "../services";
import {axiosWithCredentials} from "../config";
import {useSelector} from "react-redux";

const {getAccessToken, setAccessToken} = axiosWithCredentials;

interface RootState {
    member: { member: { id: number } }
}

const withAuth = (WrappedComponent) => {
    return (props) => {
        const router = useRouter();

        const member = useSelector((state: RootState) => state.member.member);

        if (!member) {
            (async () => {
                await router.replace("/login");
            })();
        }

        useEffect(() => {

            const accessToken = getAccessToken();
            // if no accessToken was found,then we redirect to "/" page.
            if (!accessToken) {
                (async () => {
                    await router.replace("/login");
                })();
            } else {
                (async () => {

                    try {

                        const {data} = await verifyToken(accessToken);
                        const {verified} = data;

                        // if token was verified we set the state.
                        if (!verified) {

                            setAccessToken(null);
                            await router.replace("/login");
                        }
                    } catch (error) {

                        setAccessToken(null);
                        await router.replace("/login");
                    }
                })();
            }
        }, []);

        return <WrappedComponent {...props} />;
    };
};

export default withAuth;