import React, {Fragment, useState} from "react";
import {useRouter} from "next/router";
import MuiMenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import {connect} from "react-redux";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import {withStyles} from "@material-ui/core/styles";
import {colors} from "../../../constants";

const MenuItem = withStyles({
    root: {
        color: colors.blue,
        backgroundColor: "white",
        fontSize: "0.9rem",
        "&:hover": {
            color: "white",
            backgroundColor: colors.blue,
        }
    },
})(MuiMenuItem);

const MobileMenuBar = ({member}) => {

    const router = useRouter();

    const [anchorElMore, setAnchorElMore] = useState(null);
    const [anchorElOptions, setAnchorElOptions] = useState(null);
    const [anchorElAccount, setAnchorElAccount] = useState(null);

    const onMoreMenuOpen = (event) => {
        setAnchorElMore(event.currentTarget);
    };

    const onMoreMenuClose = () => {
        setAnchorElMore(null);
    };

    const onOptionsMenuOpen = (event) => {
        setAnchorElOptions(event.currentTarget);
    };

    const onOptionsMenuClose = () => {
        setAnchorElOptions(null);
    };

    const onAccountMenuOpen = (event) => {
        setAnchorElAccount(event.currentTarget);
    };

    const onAccountMenuClose = () => {
        setAnchorElAccount(null);
    };

    const onNavigate = async (url) => {
        await router.push(url);
    }

    const onLogout = async () => {

        await router.replace("/login");
    }

    return (
        <Fragment>
            {member && member.isSuper && (<Fragment>
                    <IconButton
                        aria-label="show more"
                        aria-haspopup="true"
                        onClick={onMoreMenuOpen}
                        color="inherit">
                        <MoreIcon/>
                    </IconButton>
                    <Menu
                        id="more"
                        anchorEl={anchorElMore}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        keepMounted
                        open={Boolean(anchorElMore)}
                        onClose={onMoreMenuClose}>
                        {member && member.isSuper && (<div>
                                <MenuItem aria-controls="links" aria-haspopup="true" onClick={async () => onNavigate("/companies")}>
                                    Companies
                                </MenuItem>
                                <MenuItem aria-controls="links" aria-haspopup="true" onClick={async () => onNavigate("/value-drivers")}>
                                    Drivers
                                </MenuItem>
                                <MenuItem aria-controls="links" aria-haspopup="true" onClick={async () => onNavigate("/users")}>
                                    Users
                                </MenuItem>
                                <MenuItem aria-controls="links" aria-haspopup="true" onClick={async () => onNavigate("/roles")}>
                                    Roles
                                </MenuItem>
                                <MenuItem aria-controls="links" aria-haspopup="true" onClick={async () => onNavigate("/permissions")}>
                                    Permissions
                                </MenuItem>
                                <MenuItem aria-controls="links" aria-haspopup="true" onClick={onOptionsMenuOpen}>
                                    Options<IconExpandMore/>
                                </MenuItem>
                                <Menu
                                    id="options"
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    anchorEl={anchorElOptions}
                                    keepMounted
                                    open={Boolean(anchorElOptions)}
                                    onClose={onOptionsMenuClose}>
                                    <MenuItem aria-controls="links" aria-haspopup="true"
                                              onClick={async () => onNavigate("/industries")}>
                                        Industries
                                    </MenuItem>
                                    <MenuItem aria-controls="links" aria-haspopup="true"
                                              onClick={async () => onNavigate("/scales")}>
                                        Scales
                                    </MenuItem>
                                    <MenuItem aria-controls="links" aria-haspopup="true"
                                              onClick={async () => onNavigate("/stages")}>
                                        Stages
                                    </MenuItem>
                                    <MenuItem aria-controls="links" aria-haspopup="true"
                                              onClick={async () => onNavigate("/countries")}>
                                        Countries
                                    </MenuItem>
                                </Menu>
                            </div>
                        )}
                        <MenuItem aria-controls="VD Engine" aria-haspopup="true" onClick={async () => onNavigate("/vd-engine")}>
                            VD Center
                        </MenuItem>
                        <MenuItem aria-controls="links" aria-haspopup="true" onClick={onAccountMenuOpen}>
                            Account<IconExpandMore/>
                        </MenuItem>
                        <Menu
                            id="options"
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            anchorEl={anchorElAccount}
                            keepMounted
                            open={Boolean(anchorElAccount)}
                            onClose={onAccountMenuClose}>
                            <MenuItem onClick={async () => onNavigate("/account/profile")}>Profile</MenuItem>
                            <MenuItem onClick={async () => onLogout()}>Sign out</MenuItem>
                        </Menu>
                    </Menu>
                </Fragment>
            )}
        </Fragment>
    )
}

const mapStateToProps = state => {
    return ({
        member: state.member.member
    });
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuBar);
